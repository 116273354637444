import React, { useEffect } from "react";
import * as Icons from "@wix/wix-ui-icons-common";
import {
  Box,
  Button,
  Card,
  WixDesignSystemProvider,
  MarketingPageLayout,
  MarketingPageLayoutContent,
  Page,
  Loader,
} from "@wix/design-system";
import "@wix/design-system/styles.global.css";
import { ReactTagManager } from "react-gtm-ts";
import * as basePlatform from "@certifiedcode/base";

const tagManagerArgs = {
  code: "GTM-WZQPMC7",
};

function App() {
  ReactTagManager.init(tagManagerArgs);
  const token = new URLSearchParams(window.location.search).get("token");
  const instance = new URLSearchParams(window.location.search).get("instance");
  const [isUpgraded, setIsUpgraded] = React.useState(false);
  const [instanceData, setInstanceData] = React.useState({
    instance: {
      isFree: true,
      availablePlans: [],
      instanceId: "",
    },
  } as any);

  useEffect(() => {
    basePlatform.initialize({
      baseURL: "https://certifiedcode.wixsite.com/accw/_functions", // Replace with your API base URL
      applicationID: "3425aae5-0082-40a9-89c5-b2c84c450eaa", // Replace with your application ID
    });
    basePlatform.setIdentity(instance);
    basePlatform.setInstance(setInstanceData, setIsUpgraded);
  }, []);

  if (token) {
    return (
      <WixDesignSystemProvider features={{ newColorsBranding: true }}>
        <Page height="100vh">
          <Page.Content>
            <Box height={"90vh"} direction="vertical" verticalAlign="middle">
              <Loader text="Loading" />
            </Box>
          </Page.Content>
        </Page>
      </WixDesignSystemProvider>
    );
  }

  return (
    <WixDesignSystemProvider features={{ newColorsBranding: true }}>
      <Card>
        <MarketingPageLayout
          removeImageHorizontalPadding
          removeImageVerticalPadding
          content={
            <Box height="840px" verticalAlign="middle">
              <MarketingPageLayoutContent
                title="Auto Complete Checkout"
                content="To use this widget, contact your CSM."
                // act
                
              />
            </Box>
          }
        />
      </Card>
    </WixDesignSystemProvider>
  );
}

export default App;
